body{
  overflow-x: hidden;

}

html{
  scroll-behavior: smooth;
}

#ocult_input input {
  display: none;
}

#btn_logo_left{
  position: relative;
  top: 3px;

}



#btn_logo_right{
  position: relative;
  top:3px;
  padding-left: 5px;
}

h1,h2,h3,h4,h5,h6,p,div,span,label{
  font-family: 'Questrial', sans-serif;
}

.features_container *{
  padding:0;
}

.container{
  padding:0;
}

#blackDIV{
  display: none;
  border:1px solid black;
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.8);
  z-index: 29;
}

::-webkit-scrollbar { width: .4rem; height: 12px; }
::-webkit-scrollbar-track { background: rgba(255,255,255,0); }
::-webkit-scrollbar-thumb { background: darkmagenta; border-radius: 18px;}
:hover::-webkit-scrollbar-thumb { background: darkmagenta; }

::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover { background: var(--primary); }

::-webkit-scrollbar-thumb:horizontal:active,
::-webkit-scrollbar-thumb:vertical:active { background: indigo; }


.division{
  border-bottom: 1px solid rgb(221, 221, 221);
  height: .5rem;
}
.titles_blocks {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color:#1f2429;
}


.container_body{


  max-width: 1400px;
}
