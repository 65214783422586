@media only screen and (min-width:768px){

  .guests_modal_tsx .modal-content{
    height: auto;
    width: 50vw;
    position: fixed;
    top: 10vh;

  }
}
@media only screen and (max-width:768px){

  .guests_modal_tsx .modal-content{
    height: auto;
    position: absolute;
    left: 0;
    top:5%;




  }
}

.form-control:disabled, .form-control[readonly]{
  background-color: white !important;
  cursor: pointer;
  color: #333 !important;
}







.icon_guests_modal ion-icon{
  position: absolute;
  top: .5rem;
  left: .4rem;
  font-size: 1.4rem;
}
.icon_guests_modal input{
  padding-left: 2rem;
}
