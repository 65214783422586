/* .fc-day-sun, .fc-day-sat {
  background-color: #f0b607;
}
td.fc-day-sun, td.fc-day-sat {
  background-color: #f4f5d7;
} */

/* ! TODOS OS DIAS DA TABELA
.fc-daygrid-day-frame {
  background-color: #f0b607;
} */

/*! SOMENTE SÁBADO 
.fc-day-sat .fc-daygrid-day-frame .fc-daygrid-day-bg .fc-daygrid-bg-harness{
  background-color: #f0b607;
} */

.fc-day-today {
  background-color: #e3f4fe !important;
  border-color: #052e6e !important;
  border-width: 3px !important; 
}

.fc-day-today .fc-daygrid-day-number{
  color: #090100!important;
  font-weight: bold !important;
}

@media screen and (min-width: 783px) {
  /* your styles here */
  .fc-event-end{
    /* margin-right: 4vw !important; */
  }
  
  .fc-event-start{
    /* margin-left:2.5vw !important; */
  }  
  .fc{
    height: 1200PX !important;
  }
}

@media screen and (max-width: 783px) {
  .fc-event-end{
    /* margin-right: 10vw !important; */
  }

  .fc-event-start{
    /* margin-left: 5vw !important; */
  }
  
}

@media screen and (max-width: 390px) {
  .fc-event-end{
    /* margin-right: 10vw !important; */
  }

  .fc-event-start{
    /* margin-left: 5vw !important; */
  }
  .fc{
    height: 600px !important;
  }
}

.fc-multimonth-title{
  font-weight: bold !important;
}

.fc-col-header-cell-cushion{
  font-weight: 300 !important;
}

.fc-header-toolbar{
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.fc .fc-button .fc-icon{
  color:#000000 !important;
  font-size: 40px !important;
}

.fc-next-button{
  background-color: #fcfcfc00 !important;
  border-color: #fcfcfc00 !important;
  margin-left: 180px !important;
  margin-top: -74px !important;
}
.fc-toolbar-chunk{
  font-size: 20px !important;
}

.fc-prev-button{
  background-color: #fcfcfc00 !important;
  border-color: #fcfcfc00 !important;
  margin-bottom: -69px !important;
}

.fc-toolbar-title{
  margin-left: 88px !important;
  font-weight: bold !important;
}

.fc-today-button{
  background-color: #643be2 !important;
  border-color: #643be2 !important;
  width: 85px !important;
  height: 50px !important;
}

.fc-dayGridMonth-button{
  background-color: #294777 !important;
  border-color: #294777 !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
}

.fc-multiMonthYear-button{
  background-color: #294777 !important;
  border-color: #294777 !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
}

.fc-event{
  border-radius: 32px;  
  /* padding-left: 6px; */
  height: 20px;
  display: flex;
  justify-content: flex-start;
  /* background-color: #ccc; */
  

}

.fc-event , a , i{
  text-overflow: ellipsis;
  overflow: hidden;
  width: '100%';
  display: block;
  justify-content: flex-start;
  

}

.blocked-day{
  background-color: #464646;
  border-color: #696969;
}

/* .fc-daygrid-event-harness , .element.style{
  top:0 !important; 
  background-color: #0070c9;
  border-color: #0070c9;
}
.fc-day-grid-event > .fc-content {
  white-space: normal !important;
  text-overflow: ellipsis !important;
  max-height:20px !important;
}
.fc-day-grid-event > .fc-content:hover {
  max-height:none!important; 
}

.fc-daygrid-event{
  text-overflow: ellipsis;
}
.fc-content{white-space:normal} */


td.fc-day-mon , td.fc-day-tue, td.fc-day-wed , td.fc-day-thu , td.fc-day-fri{
  background-color: #ffffff;
}
.fc-day-sun, .fc-day-sat {
  background-color: #fbfbfb1e;
}

/* DIAS FORA DO MES */
.fc-day-disabled {
  visibility:hidden;
}

html,
body,
body > div { /* the react root */
  margin: 0;
  padding: 0;
  /* height: 100%; */
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}


.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}