.container_footer{
  background-color:rgb(243,243,243);

}

.container_footer ul label{

}
.container_footer li{
  list-style-type: none;
}

.container_footer ion-icon{

  font-size: 1.5rem;
  margin-top: .4rem;
}

#footer_razaosocial{
  font-size:.8rem;
}


.container_footer ul li a{
  color:#333;
  cursor: pointer;
}