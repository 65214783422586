
.container_to_sticky{

}
.container_sticky_real{
  z-index: 30;

  position: sticky;
  top:5%;

}


#membro_prime_modal{
  width: 100%;
  left:-.5rem

}

.payblock_forms .container_sticky_real{
  position: sticky;
  top:2%;
}
.container_direita_payblock{
  box-shadow: 0px 3px 10px #d1d1d1;
  border-radius: 8px;


}
#block_whatsapp{
  box-shadow: 0px 3px 10px #d1d1d1;
  border-radius: 8px;


  word-wrap: break-word;

}
#block_whatsapp ion-icon{
  font-size:45px;
  color:var(--primary);


}
#block_whatsapp img{
  width: 45px;
  color:var(--primary);
  word-wrap: break-word;
  word-break: break-all;


}
#block_whatsapp_no_box ion-icon{
  font-size:45px;
  color:var(--primary);


}
#block_whatsapp_no_box img{
  width: 45px;
  color:var(--primary);
  word-wrap: break-word;
  word-break: break-all;
}
#block_whatsapp_no_box button{
  max-width: 300px;

}
.teste{border:1px solid black;}

.reserve_agora_wpp{
  font-size: .9rem;
}

.alguma_duvida_wpp{
  font-size: 1.1rem;
}

#hospedes{
width: 100%;
    border: 1px solid #D1D1D1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

}

#hospedes::after{
  margin-left: .7rem;
  vertical-align: .15rem;
}
.dropdown-menu{
  box-shadow: 2px 2px 7px rgba(0,0,150,.3);
  width: 100%;
  padding:1.2rem .3rem 1rem 0rem;
}

.payBlockBorder{
  border:0px 1px 1px 1px solid rgb(200, 200, 200);

}

.calendar_modal_left input{
  border-radius: unset !important;
  border-top-left-radius:4px !important;
}
.calendar_modal_right input{
  border-radius: unset !important;
  border-top-right-radius: 4px !important;
}

.guests_modal input{
  border-radius: unset !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

#add_people_btn{
  background-color: white;
  border:1px solid #d1d1d1;
  padding:.3rem;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor:pointer;
}
#membro_prime_modal .modal-content{
  height: auto !important;
}

#gift_icon{
  color:var(--primary);
  font-size: 6rem;
}
#remove_people_btn{
  background-color: white;
  border:1px solid #d1d1d1;
  padding:.3rem;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor:pointer;
}








#btn_reservar_payBlock{
  width: 99%;

  font-size: 1.2rem;

}


#nao_cobrado_div{
  font-size: .9rem;
}
