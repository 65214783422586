.cancelada_container{
  box-shadow: 0px 1px 5px #d1d1d1;
  padding: 1rem;
  border-radius: 8px;
}

#concluido_div_alert{
  width: 80%;
}
#alright_alert ion-icon{
  font-size: 3rem;
  position: absolute;
  color:var(--success);
  left:0;
  top:0 !important;
}
#alright_alert h1{
  max-width: 310px;
  position: relative;
}

.concluida_reservalink{
  box-shadow: 0px 0px 5px #d1d1d1;
  padding: 1.5rem;
  border-radius:8px;
}

@media only screen and (max-width:520px){
  #concluido_div_alert{
    width: 95%;
  }
}
@media only screen and (max-width:438px){
  #alright_alert ion-icon{
    font-size: 2.8rem;
  }
  #alright_alert h1{
    font-size: 2.3rem !important;
  }
}
@media only screen and (max-width:420px){
  #alright_alert ion-icon{
    font-size: 2.4rem;
    top:-.2rem !important;
  }
  #alright_alert h1{
    font-size: 1.9rem !important;
  }
}
@media only screen and (max-width:365px){
  #alright_alert ion-icon{
    font-size: 2.2rem;
  }
  #alright_alert h1{
    font-size: 1.7rem !important;
  }
}
@media only screen and (max-width:340px){
  #alright_alert ion-icon{
    font-size: 2rem;
  }
  #alright_alert h1{
    font-size: 1.5rem !important;
  }
}
