

#carrosel_div_RoomType::-webkit-scrollbar-track { background: #fff; }

#carrosel_div_RoomType::-webkit-scrollbar {
  height: .3rem;
  background: #fff;
}
#carrosel_div_RoomType::-webkit-scrollbar-thumb {
  background: purple;
  border-radius: 20px;
}

#container_carousel{
  position:relative;
  width: 100vw;

}
#carrosel_div_RoomType{
    display: flex;
    overflow: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    height: 46.7vh;
    z-index: 1;



}

#btn_rightTo_RoomType{
  position:absolute;
  top:40%;
  right: 5%;
  padding:.7rem;
  border-radius: 24px;
  border:none;
  outline: none;
  background-color: rgba(255,255,255,.8);
  cursor: pointer;
  font-size: 20px;
  z-index: 99;
  width: 42px;
  height: 42px;

}
#btn_leftTo_RoomType{
  position:absolute;
  justify-content: center;
  align-items: center;
  top:40%;
  left: 5%;
  padding:.7rem;
  border-radius: 24px;
  border:none;
  outline: none;
  background-color: rgba(255,255,255,.8);
  cursor: pointer;
  font-size: 20px;
  z-index: 99;
  width: 42px;
  height: 42px;
}


#btn_leftTo_RoomType:hover,#btn_rightTo_RoomType:hover{
  background-color: rgba(240, 240, 240, 0.8);
}

#amount_imgs{
  position: absolute;
  bottom:5%;
  right: 5%;
  color:white;
  font-size:.8rem;
  font-weight: bold;
  padding:.5rem;
  background-color: rgba(0,0,0,.7);
  border-radius: 8px;
}
#open_imgs{
  position: absolute;
  top:5%;
  right: 5%;
  color:white;
  font-size:1.3rem;
  font-weight: bold;
  padding:.5rem;
  background-color: rgba(0,0,0,.7);
  border-radius: 8px;
}

/* #open_imgs_label{
  position: absolute;
  top:7%;
  right: calc(5% + 2.7rem);
  color:white;
  font-size:1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor:pointer;
  opacity: 0;
  display: none;
  transition:.5s;
}
.container_img_open:hover > #open_imgs{
  padding:.5rem .5rem .5rem 6rem;
}
.container_img_open:hover > #open_imgs_label{
  opacity: 1;
  display: block;
} */

#btn_leftTo_RoomType:active{
  transition:.1s;
  background-color: rgba(255,255,255,.8);
}
#btn_rightTo_RoomType:active{
  transition:.1s;
  background-color: #ddd;
}

.large_div{
  height: 46vh;
  width: 53vh;
}


.small_div{
  height: 22.8vh;
  width:  35vh;
  margin:0 .4rem 0 .4rem;


}

.small_div::after{
  height: 22.8vh;
  width:  35vh;
  margin:0 .4rem 0 .4rem;

}
.large{
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;

}



.small{
  border-radius: 12px;
  width: 100%;
  height: 100%;
  margin-bottom: .5vh;
  object-fit: cover;
}





@media only screen and (min-width: 900px){
  #btn_rightTo_RoomType{

    width: 42px;
    height: 42px;

  }
  #btn_leftTo_RoomType{

    width: 42px;
    height: 42px;

  }
}
@media only screen and (min-width: 1200px){
  #btn_rightTo_RoomType{

    width: 42px;
    height: 42px;

  }
  #btn_leftTo_RoomType{

    width: 42px;
    height: 42px;

  }

  #amount_imgs{

    font-size:.9rem;
    padding:.6rem;

  }
}
