#containerGeral{
    /* border:1px solid #d1d1d1; */
    max-width: 600px;
}

#blueContainer{
    background-color: var(--primary);
    padding: 24px;
    

    color:white;
}

