.cancelada_container ion-icon{
  font-size: 2.15rem;
  position: relative;
  top:.5rem !important;
 
}



@media only screen and (max-width:464px){
  .cancelada_container ion-icon{
    font-size: 2rem;
  }

  .cancelada_container h3{
    font-size:1.6rem;
  }
}
@media only screen and (max-width:433px){
  .cancelada_container ion-icon{
    font-size: 1.9rem;
    top:.4rem !important;
  }

  .cancelada_container h3{
    font-size:1.4rem;
  }
}
@media only screen and (max-width:395px){
  .cancelada_container ion-icon{
    font-size: 1.8rem;
    top:.55rem !important;
  }

  .cancelada_container h3{
    font-size:1.3rem;
  }
}
@media only screen and (max-width:360px){
  .cancelada_container ion-icon{
    font-size: 1.55rem;
    top:.45rem !important;
  }

  .cancelada_container h3{
    font-size:1.1rem;
  }
}
