#spinner_loading_container{

  /* width: 100%; */
  height: 70vh;
}
/* #spinner_loading{
  position: absolute;
  top:40%;
} */
.container_reservation_link .container_roomtype_card{
  box-shadow: 0px 3px 5px #d1d1d1;
  border-radius: 8px;
}

#desc_card_reservation{
  position: absolute;
  left: 7.5%;
  bottom: 5%;
}

.footer_mobile_reservation_link{
  background-color: white;
  border-top: 1px solid #d1d1d1;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.btn-icon{
  position: relative;
  top:2%
}
.alert_RNC h1{
  font-size: 2rem !important;
  line-height: 1.8rem;
  margin-bottom: 1.5rem;
}

#arrow_concluirDownIcon{
  position: absolute;
  font-size: 1.8rem;
  left:-2.3rem;
}

/* BUTTON START */
@media only screen and (max-width:407px){
  .alert_RNC button{
    font-size: 1.15rem;
  }
}
@media only screen and (max-width:384px){
  .alert_RNC button{
    font-size: 1.1rem;
  }
}
@media only screen and (max-width:384px){
  .alert_RNC button{
    font-size: 1.05rem;
  }
  #arrow_concluirDownIcon{
    top:-.1rem;
  }
}
@media only screen and (max-width:384px){
  .alert_RNC button{
    font-size: 1rem;
  }
  #arrow_concluirDownIcon{
  }
}
@media only screen and (max-width:350px){
  .alert_RNC button{
    font-size: .95rem;
  }
  #arrow_concluirDownIcon{
    top:-.25rem;
  }
}
@media only screen and (max-width:339px){
  .alert_RNC button{
    font-size: .90rem;
  }
  #arrow_concluirDownIcon{
  }
}


/* BUTTON END */





@media only screen and (max-width:362px){
  .alert_RNC h1{
    font-size: 1.8rem !important;
  }

}
@media only screen and (max-width:331px){
  .alert_RNC h1{
    font-size: 1.6rem !important;
  }

}
@media only screen and (max-width: 764px){

}
