#container_footer_pay_block{
  position: fixed;
  bottom: 0;
  left:0;
  background-color: white;
  border-top: 1px solid #e1e1e1;
  z-index: 25;
}


.valor_medio_pb_fixed{
  font-size: 1.2rem;
  font-weight: bold;
}

#label_valor_medio_pb_fixed{
  font-weight: normal;
  font-size: 1rem;
}
#apartir{
  font-size: .7rem;
  position: absolute;
  top: -.5rem;
}

/* CSS DO MODAL */

.pay_block_modal{
  transform: none !important;
}

.pay_block_modal .modal-content{
  position:fixed !important;
  left:0 !important;
  top:0 !important;
  height: 100vh !important;
  border:unset !important;
}







#container_PayBlockMobileNew #btn_fechar{
  position: absolute;
  font-size:1.3rem;
  box-shadow: 0px 3px 5px #d1d1d1;
  border-radius: 50px;
  padding:.2rem;
  left: 3%;
  top:2%;
}

#superior_PayBlockMobileNew{
  padding-bottom: 1rem !important;
  margin-top: 2rem !important;

}


#superior_PayBlockMobileNew #input_checkin{
  border-radius: 0px;
  border-top-left-radius: 4px;
}
#superior_PayBlockMobileNew #input_checkout{
  border-radius: 0px;
  border-top-right-radius: 4px;
}
#superior_PayBlockMobileNew #input_hospedes{
  border-radius: 0px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

}

#superior_PayBlockMobileNew div.verificar_disponibilidade label{
  font-size: .9rem;
  line-height: 1rem;
}

#superior_PayBlockMobileNew button{
  font-size: 1.2rem;
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-bottom: .4rem;

}
#superior_PayBlockMobileNew #block_whatsapp button{
  font-size:.9rem;
  padding-top: .3rem;
  padding-bottom: .3rem;
}



.container_RoomsSelect ion-icon{
  font-size:1.5rem;
}

.container_RoomsSelect div > ion-icon{
  padding:.1rem;
  cursor:pointer;
  box-shadow: 0px 2px 5px #d1d1d1;
  border-radius: 50px;
}

.number_of_room{
  font-weight: bold;
  font-size:1.2rem;
}

.container_idade_crianças{
  /* border:1px solid var(--primary); */
  border-radius: 8px;
  max-width: 200px !important;
}

.container_idade_crianças label{
  font-size: .8rem;
  font-weight: bolder;
  margin-left: 1rem;
}

.container_idade_crianças .btn-secondary{
  color:#938e96;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  background-color: white;
  border:1px solid #c8c8c8;
  width: 148px;
  margin-top: -.6rem;
  margin-bottom: .3rem;
}
.container_idade_crianças .btn-secondary:hover{
  background-color: #fff;
  border:1px solid #ced4da;
  color:black;
}


.container_idade_crianças .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: var(--primary);
  color:white;

}


.container_idade_crianças .dropdown-toggle::after {
  float: right;
  margin-top: .5rem;
}

.container_idade_crianças .dropdown-menu{
  height: 10rem;
  overflow-y: auto;

}



.container_RoomsSelect .btn_room{
  width: 150px;

}

@media only screen and (max-height:625px){
  .pay_block_modal .modal-content{
    overflow-y: auto;
  }
}

@media only screen and (max-width:324px){
  #superior_PayBlockMobileNew .verificar_disponibilidade button {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width:356px){
  #superior_PayBlockMobileNew button{
    font-size: 1rem;
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: .4rem;
  
  }
}



