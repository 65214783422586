
.container_footerayrton{
  color:white;
  background-color:black;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 2rem;
}


#limitefooter_ayrton{
  max-width: 700px !important;

}
.container_footerayrton img{
  max-width: 25rem;
  height: 75%;
}

.container_footerayrton li{
  list-style-type: none;
}
.container_footerayrton li a{
  color: white;
}
/*
.container_footerayrton *{
  border:1px solid white;
} */


@media only screen and (max-width:764px){
  main #container_footerayrton{
    padding-bottom: 5rem !important;


  }
  .container_footerayrton img{
    max-width: 25rem;
    height: 80%;
  }
}

@media only screen and (max-width:765px){
  #container_footerayrton{
    font-size: .8rem;


  }

  .container_footerayrton img{
    height: 90%;
  }
}
