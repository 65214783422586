.cookie-bar{
  background-color: #fff;
  color:black;
  box-shadow: 0px 3px 8px #d1d1d1;
  border-radius: 8px;
  padding: 1.3rem;
  position: fixed;
  left:5vw;
  bottom:1vh;
  z-index: 50;
  transition: .5s;
}
.cookie-text{
  line-height: 1.4rem;
}

@media only screen and (max-width:1000px) {
  .cookie-bar{
    width: 50vw;
    left:5vw;
  }
}
@media only screen and (min-width:1001px) {
  .cookie-bar{
    width: 50vw;
    left:5vw;
  }
}
@media only screen and (max-width:810px) {
  main .cookie-bar{
    width: 70vw;
    left:15vw;
    bottom:6rem;
  }
  .container_filter_room .cookie-bar{
    width: 70vw;
    left:15vw;
    bottom:11rem;
  }
  #containerReservationLinkPage .cookie-bar{
    width: 70vw;
  }

}


@media only screen and (max-width:575px) {
  main .cookie-bar{
    width: 90vw !important;
    left:5vw;
  }
  .container_filter_room .cookie-bar{
    width: 90vw !important;
    left:5vw;
  }
  #containerReservationLinkPage .cookie-bar{
    width: 90vw !important;
    left:5vw;
  }
  .cookie-bar button{
    width:100%
  }
}
