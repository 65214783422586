.container_roomtype{

}


.container_roomtype_card{
  padding:3rem;
  min-width: 270px;

}
.container_roomtype_card .carousel-inner {

  border-radius: 8px;
}


.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{
  background-color:var(--primary) !important;

}

.container_right{
  height: 13rem;
}

.container_roomtype .carousel-item{
  max-height: 13rem;
}


.container_roomtype img{

  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;

}

.container_roomtype .roomype_title{
  font-size: 1.5rem;
  font-weight: bold;
  color:#1f2429;
}
.container_roomtype .roomype_desc{
  font-size: .9rem;
  word-wrap: break-word;
  line-height: 1.4rem;

}

.container_roomtype .roomype_price{
}

.container_roomtype .container_price{
  position: absolute;
  bottom: 5%;
  width: 100%;
}

.container_roomtype .container_price div{
  font-weight: bold;
}

#barra_noite{
  font-size: .7rem;
  margin-top: -.4rem;
}



.container_roomtype .container_price button{
  font-size: .7rem;
  padding:.3rem .5rem .3rem .5rem;
}


.container_img_open_roomType ion-icon{
  position: absolute;
  top:5px;
  left: 5px;
  color:white;
  font-size:.7rem;
  font-weight: bold;
  padding:.4rem;
  background-color: rgba(0,0,0,.7);
  border-radius: 8px;
  cursor:pointer;
  z-index: 24;
}


@media only screen and (max-width: 430px) {
  .container_roomtype .roomype_title{
    font-size: 1.2rem;
  }



  .container_roomtype .roomype_desc{
    line-height: 1.2rem;

  }


}
#apartircard{
  font-size: .6rem;
  position: absolute;
  top: -.4rem;
}


@media only screen and (min-width: 1200px) {


  .container_roomtype .roomype_desc{
    font-size: 1rem;


  }

  .container_roomtype .container_price button{
    padding:.3rem .7rem .3rem .7rem;
    font-size:1.1rem;
  }

  .container_roomtype .container_price div{
    font-size:1.1rem;
  }



}

@media only screen and (min-width: 1400px) {

 
  .container_roomtype .roomype_desc{
    font-size: 1rem;


  }
  .container_roomtype .roomype_title{
    font-size:1.8rem;
  }

  .container_roomtype .roomype_desc{
    font-size: 1.3rem;
    line-height: 2rem;
    }
  .container_roomtype .container_price div{
    font-size:1.3rem;
  }
  .container_roomtype .container_price button{
    font-size: 1.3rem;
    padding:.3rem .7rem .3rem .7rem;

  }


}
