.container_filter_room_sub{
  box-shadow: 0px 3px 10px #d1d1d1;
  padding:0 1rem 0rem 1rem;
  border-radius: 8px;
  max-width: 650px;

}
.container_geral_revisao_filter{
  position: sticky;
  top:3rem;

}


.badge_oferta_mobile_no_msg ion-icon{
  position: relative;
  top:.1rem !important;
}
.badge_oferta_mobile_no_msg{
  font-size:1.2rem;
}

.container_bonequinhos{
  width: 100%;

}
.icon_adults_amount_filter{
  max-width: 40px !important;
  min-width: 40px !important;
  top:.055rem;

}

.container_img_open_filterRoom ion-icon{
  position: absolute;
  top:5px;
  left: 5px;
  color:white;
  font-size:.7rem;
  font-weight: bold;
  padding:.4rem;
  background-color: rgba(0,0,0,.7);
  border-radius: 8px;
  cursor:pointer;

}


.icon_childs_amount_filter{
  padding-top: 2px!important;

}

.icon_adults_amount_filter label{
  position: absolute;
  top:1.1rem;
  left:0rem;
  font-size: .6rem;
}

.icon_childs_amount_filter label{
  position: absolute;
  top:1.15rem;
  left:-.2rem;
  font-size: .6rem;
}
.icon_childs_amount_filter img{
  width: .6rem;
  position: relative;
  top:.01rem;
  margin-right: .1rem;


}

.carousel-item{
  height: 15rem;
}

.carousel-item img{
  max-height: 320px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.calendar_modal_filter input{
  border-radius: unset !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.guests_modal_filter input{
  border-radius: unset !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.container_revisao_filter{
  box-shadow: 0px 3px 10px #d1d1d1;
  border-radius: 8px;
  padding:.7rem !important;
  max-width: 350px;
}
.container_revisao_filter h5{
font-size: 1.1rem;
}
.room_counter_btnion{
  font-size: 1.3rem;
  color:var(--primary);
}
.room_counter_btnion ion-icon{
  box-shadow: 0px 3px 5px #d1d1d1;
  color: black;
  padding: .3rem;
  border-radius: 50px;
  cursor: pointer;
}




/* .container_filter_room_sub input:hover{
  color:black;
}
#card_padding_left{
  padding-left: 3.3rem;
} */
.container_filter_room_sub .btn-secondary{
  /* color: black; */
  /* background-color: white !important; */
  border: 1px solid var(--primary);
}
.container_filter_room_sub .btn-secondary:hover{

  background-color: #f3f3f3;
  border:1px solid var(--primary);
  /* color:black; */

}
.container_filter_room_sub .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: var(--primary);
  color:white;

}


.container_revisao_filter .progress-bar,.container_revisao_filter .progress,
.container_revisao_filter_mobile .progress-bar,.container_revisao_filter_mobile .progress{
  border-radius: 12px !important;
}



.container_revisao_filter_mobile{
  padding-top: 1rem .5rem 0 .5rem !important;

  border-top: 1px solid #d1d1d1;
  background-color: white;
  position: fixed;
  bottom: -3px !important;
  height: 10vh !important;
  min-height: 10.9rem;
  z-index: 15;
}

#desc_card_filter{
  position: absolute;
  left: 3%;
  bottom: 5%;
}


.ml-less-50{
  margin-left:-35px !important;
}
.max_occupants ion-icon{
  font-size:1.4rem;
}
@media only screen and (max-width:767px){

  .rem15_footer_ayrton{
    padding-bottom: 10.5rem !important;
  }
}

@media only screen and (min-width:768px){
  .container_filter_room_sub{

    min-width: 465px;
  
  }
}

@media only screen and (max-width:454px){

  #busca_mobile_filter_room input{
    font-size: .7rem;
    height: 2.4rem;
  }
}
