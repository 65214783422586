

.reservar_anchor div{

  width: 150px;
  position: relative;
}
.reservar_anchor ion-icon{

  position: absolute !important;
  left:0;
  top:-.1rem !important;
}
.reservar_anchor button{
  padding-left: 2rem;
}
#img_open_formpage_icon{
  position: absolute;
  top:5px;
  left: 5px;
  color:white;
  font-size:.7rem;
  font-weight: bold;
  padding:.4rem;
  background-color: rgba(0,0,0,.7);
  border-radius: 8px;
  cursor:pointer;
  z-index: 24;
}



#back_btn{
  position:absolute !important;
  top:0 !important;
  box-shadow:0px 0px 5px #d1d1d1 !important;
  padding:.3rem !important;
  border-radius:50px !important;
  cursor:pointer !important;
  font-size: 1.6rem;
}
#back_btn:active{
  background-color: #f1f1f1;
}
#back_btn:hover{
  background-color: #fafafa;
}


.forms_page_container_esquerda{
  box-shadow: 0px 3px 10px #d1d1d1;
  border-radius: 8px;
  max-width: 600px;
}

.container_btn_reservar{
  width: 100%;
}
.container_btn_reservar .text-primary{
  color:white !important;
  z-index: 9999;
}
.container_btn_reservar button{
  width: 100%;
  margin:auto !important;
}
#reservar_btn_spinner_container{
  position: absolute;
}

#reservar_btn_spinner{
  position: relative;
  left: -4rem;
  top: .8rem;
  width: 24px;
  height: 24px;
}

#spinner_input_cep_container{
  position: absolute;
}
#snipper_input_cep{
  width: 18px;
  height: 18px;
  position: relative;
  top:.5rem;
  left: 5rem;
}

@media only screen and (max-width:1090px){
  #snipper_input_cep{
    left:4.5rem;
  }
}
@media only screen and (max-width:960px){
  #snipper_input_cep{
    left:4rem;
  }
}
@media only screen and (max-width:900px){
  #snipper_input_cep{
    left:3.5rem;
  }
}
@media only screen and (max-width:815px){
  #snipper_input_cep{
    left:3rem;
  }
}
@media only screen and (max-width:767px){
  #snipper_input_cep{
    left:5rem;
  }
}
@media only screen and (max-width:675px){
  #snipper_input_cep{
    left:4.5rem;
  }
}
@media only screen and (max-width:610px){
  #snipper_input_cep{
    left:4rem;
  }
}
@media only screen and (max-width:575px){
  #snipper_input_cep{
    left:30vw;
  }
}
@media only screen and (max-width:420px){
  #snipper_input_cep{
    left:27vw;
  }
}
@media only screen and (max-width:330px){
  #snipper_input_cep{
    left:24vw;
  }
}


#container_form_maxWidth{
  max-width: 1100px;
  margin:auto !important;
}
.revision_block{
  min-width: 300px;
}

.payblock_forms{
  position: relative;
}
.container_sticky_form_page{
  position:sticky;
  top:3.5rem;
}
.carousel-control-prev{
  display: none !important;
}

.carousel-control-next{
  display: none !important;
}
.carousel-indicators{
  display: none !important;
}
.revision_block_pc{
  box-shadow: 0px 3px 10px #d1d1d1;
  border-radius: 8px;
  max-width: 435px;

  padding-top: 1rem !important;

}


.dropdown-menu.show:hover::-webkit-scrollbar{ width: .8rem; height: 20px; }
.dropdown-menu.show::-webkit-scrollbar-track { background: rgba(255,255,255,1); }









#ano_icon_div{
 max-width: 115px;
}
#ano_icon_div input{

}
#ano_icon_div ion-icon{
  color:#333;
  top:.4rem;
}
.teste{
  border:1px solid black;
}
#btn_logo_left{
  position: relative;
  top: 3px;

}

#btn_logo_right{
  position: relative;
  top:3px;
  padding-left: 5px;
}

.forms_baixo label{
  margin-bottom: -.5rem;
  font-weight: bold;
}

.forms_baixo input{
  margin-bottom: 1rem;
}

.forms_page_container_esquerda h3{
  font-weight: bolder;
  font-size: 2rem;
}

.forms_page_container_esquerda .dropdown-menu.show{
  height: 300%;
  overflow-y: auto;
}
/*
#pais_dropdown .dropdown-menu {
  top:-13.3rem !important;

} */

#dados_seguros_label{
  padding-left: 11px !important;
}

.hosp_and_beds_line{
  font-size: 1.2rem;

}
#desc_card_reservation_form{
  position: absolute;
  left: 7.5%;
  bottom: 30%;
}
.forms_page_container_esquerda ion-icon{
  padding-right: .2rem;
  font-size: 1.7rem;
  position: relative;
  top:20%;
}
.forms_baixo ion-icon{
  font-size:1.5rem;
  position: absolute;
  left: .5rem;
  top:10%;
}

#icon_input{
  position: absolute;
  top:1.9rem !important;
  color:#333;
}
#img_icon_card{
  width: 40px;
  height: 40px;
  position: absolute;
  left: .4rem;
  /* border-radius: 4px; */
  /* background-color: rgba(255,255,255,0); */

}
#down_card_icon{
  position: absolute;
  top:.4rem !important;
  color:#333;
}
.forms_page_container_esquerda .input_haveIcon{
  padding-left: 2.3rem;

}

.forms_page_container_esquerda .input_iconActiveCard{
  padding-left: 3.3rem !important;

}

.forms_page_container_esquerda input:hover{
  color:black !important;
}
.forms_page_container_esquerda input{
  color:#333 !important;
}


#card_padding_left{
  padding-left: 3.3rem;
}
.forms_page_container_esquerda .btn-secondary{
  /* color: #333; */
  color: #333;
  background-color: white;
  border: 1px solid #c8c8c8;
  width: 100%;
  padding-left: 2.3rem;
}
.forms_page_container_esquerda .btn-secondary:hover{

  background-color: #fff;
  border:1px solid #ced4da;
  color:black;

}
.forms_page_container_esquerda .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: var(--primary);
  color:white;

}

.forms_page_container_esquerda .dropdown-toggle::after {
  position: absolute;
  top:1rem;
  right:7%;
}




#error_reservar_alert{
  padding-left:2rem;
}
#error_reservar_alert ion-icon{
  position: absolute;
  left:.4rem;
  top:.65rem;
}

.grecaptcha-badge{
  display:none !important;
}
