

/* AREA DE TESTE */
.container_amenities{
}
.container_amenities div{
  margin:0;
}

/* FIM TESTE */



#allAmenities_div{
  display: none;
}

#btn_all_amenities{
  font-size: 2.5rem;
  padding:.5rem;
  border-radius: 50px;
  margin-top: -1.3rem;
  cursor: pointer;
}
#btn_some_amenities{
  font-size: 2.5rem;
  padding:.5rem;
  border-radius: 50px;
  margin-top: -1.3rem;
  cursor: pointer;
}

#ver_mais{
  margin-top: .8rem;
  cursor: pointer;
}
