.am-calendar{
  font-family: 'Questrial', sans-serif !important;

}

.am-calendar .week-panel .cell-grey{
  color: black !important;
}
.am-calendar .single-month .row .cell .info{
  width:200% !important;
}
.am-calendar .single-month .row .cell .date-wrapper .date{
  cursor: pointer;
}
.am-calendar .single-month .row .cell .date-wrapper .grey{
  color:unset !important;
}

.am-calendar .single-month .row .cell .date-wrapper .disable{
  color:#bbb !important;
  cursor: default;
}
.am-calendar .single-month .row .cell .date-wrapper .date-selected{
  color:#fff !important;
  background-color: var(--primary) !important;

}



.am-calendar .confirm-panel .button{
  background-color: var(--primary) !important;
  width: 8rem !important;
  cursor: pointer !important;
}

.am-calendar .week-panel .cell{
  font-size: 1rem !important;
}
.am-calendar .single-month .row .cell .info{
  color: var(--primary) !important;
  font-weight: bold;
  font-size: .8rem !important;
}

.am-calendar .single-month .month-title{
  font-weight: bold;
  font-size: 1.1rem;
  color:var(--primary);
  text-decoration: underline;
}

.am-calendar .confirm-panel .info {
  font-size: 1rem !important;
  font-weight: bold;
}

.am-calendar .header .right{
  color:var(--primary) !important;
  font-weight: bold;
  cursor: pointer;

}

.am-calendar .header .left{
  color:var(--primary) !important;
  cursor: pointer;
}

.calendar_pc_size .am-calendar .content {
  width:65% !important;
  height: 75% !important;
  top: 13%;
  left:20%;
  border-radius: 14px;


}

.calendar_pc_size .am-calendar .date-picker{
  background-color: rgba(255,255,255,0) !important;
}
.calendar_pc_size .am-calendar .date-picker .wrapper,
.calendar_pc_size .calendar_pc_size .am-calendar .content,
.calendar_pc_size .am-calendar .date-picker{
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;

}














.icon_calendar_modal ion-icon{
  position: absolute;
  top: .5rem;
  left: .4rem;
  font-size: 1.4rem;
}
.icon_calendar_modal input{
  padding-left: 2rem;
}
