#location_block{
box-shadow: 0px 3px 10px #d1d1d1;
 height: 20rem;
 z-index: 500;

}

#location_block iframe{
  width: 100%;
  height: 100%;
}

#location_fullscreen{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top:0;

}
